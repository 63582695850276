<template>
  <q-img :placeholder-src="placeholderSrc" :src="src">
    <template v-slot:loading>
      <q-spinner-tail color="primary" />
    </template>
  </q-img>
</template>

<script setup>
import { computed } from "vue";
import { urlFor } from "src/boot/sanityUrlFor";
import { useResponsiveImageWidth } from "src/composables/image/useResponsiveImageWidth";

defineOptions({ name: "ImageComponent" });

const props = defineProps({
  media: { type: Object, default: () => ({}) },
  width: { type: Number, default: 640 },
});

const responsiveWidth = computed(() => useResponsiveImageWidth(props.width));

const placeholderSrc = computed(
  () => props.media?.image?.asset?.metadata?.lqip || null
);

const src = computed(() => {
  const imageUrl = props.media?.image?.asset?.url;
  if (imageUrl) {
    return urlFor(imageUrl).width(Math.round(responsiveWidth.value)).url();
  }
  return null;
});
</script>
